.HeaderServices {
	width: 100vw;
	text-align: center;
	color: var(--color-text);
}

.HeaderServices .heading {
	font-family: var(--font-family-heading);
	font-size: 300%;
	margin: 2rem 0;
}

@media screen and (max-width: 900px) {
	.HeaderServices .heading {
		font-size: 150%;
	}
}
