.Navbar {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
}

.Navbar .navbar-links {
	flex: 1;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.Navbar .navbar-links-logo {
	display: flex;
	align-items: center;
	color: var(--color-text);
	font-family: var(--font-family-body);
	font-size: x-large;
}

.Navbar .navbar-links-logo img {
	height: 50px;
	width: auto;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.Navbar .navbar-links-logo p {
	margin-top: 18px;
}

.Navbar .navbar-links-container {
	display: flex;
	font-family: var(--font-family-body);
	margin-top: 18px;
}

.Navbar .links {
	display: flex;
	font-size: small;
	color: var(--color-text);
}

.Navbar .links a {
	margin: 0 0.5rem;
	padding: 0.5rem 2rem;
	cursor: pointer;
	border-radius: 25px;
	transition: 0.5s ease-in-out;
}

.Navbar .links a:hover,
.Navbar .links a.active {
	background-color: var(--color-text);
	color: #fff;
}

.Navbar .navbar-links-container button {
	margin: 0 0.5rem;
	padding: 0.5rem 2rem;
	cursor: pointer;
	background-color: var(--color-link);
	color: #fff;
	border: none;
	outline: none;
	border-radius: 10px;
	transition: 0.5s ease;
}

.Navbar .navbar-links-container button:hover {
	scale: 1.2;
}

.Navbar .navbar-menu {
	display: none;
}

.Navbar .navbar-menu::before,
.Navbar .navbar-menu::after,
.Navbar .navbar-menu .menu {
	content: "";
	width: 2em;
	height: 0.2em;
	background-color: var(--color-text);
	border-radius: 100px;
	transform-origin: right center;
	transition: 0.2s;
}

.Navbar .navbar-menu.clicked .menu {
	opacity: 0;
	scale: 0.1;
}

.Navbar .navbar-menu.clicked::before {
	rotate: -45deg;
	translate: 0 calc(-0.1em - 0.3px);
}

.Navbar .navbar-menu.clicked::after {
	rotate: 45deg;
	translate: 0 calc(0.1em + 0.3px);
}

.Navbar .navbar-menu-links-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--color-bg);
	transition: 0.5s;
	z-index: 99;
}

.Navbar .navbar-menu-links-container.hidden {
	margin-top: -70vh;
	opacity: 0;
	visibility: hidden;
}

.Navbar .navbar-menu-links-container.visible {
	margin-top: 0;
	opacity: 1;
	visibility: visible;
}

.Navbar .menu-links {
	display: flex;
	width: 100vw;
	height: 100vh;
	flex-direction: column;
	align-items: center;
	margin-top: 80px;
	font-family: var(--font-family-body);
	color: var(--color-text);
	font-size: x-large;
}

.Navbar .menu-links .button-container {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.Navbar .menu-links .button-container .book-icon {
	position: absolute;
	margin-left: -7rem;
	margin-top: -0.2rem;
	justify-self: start;
	pointer-events: none;
	transition: 0.5s ease;
}

.Navbar .menu-links button {
	padding: 15px;
	width: 100vw;
	cursor: pointer;
	border: none;
	outline: none;
	color: var(--color-text);
	font-size: large;
	background-color: transparent;
	transition: 0.5s ease;
}

.Navbar .menu-links button:hover {
	background-color: var(--color-text);
	color: #fff;
}

.Navbar .menu-links button:hover + .book-icon {
	color: #fff;
}

.Navbar .menu-links a {
	padding: 15px;
	width: 100vw;
	cursor: pointer;
	text-align: center;
}

.Navbar .menu-links a.active,
.Navbar .menu-links a:hover {
	background-color: var(--color-text);
	color: #fff;
}

@media screen and (max-width: 900px) {
	.Navbar {
		height: 67px;
		background-color: #fff;
	}

	.Navbar .navbar-links-logo img {
		height: 40px;
		width: auto;
	}

	.Navbar .navbar-links-logo p {
		margin-top: 10px;
	}

	.Navbar .navbar-links-container {
		display: none;
	}

	.Navbar .navbar-menu {
		display: flex;
		flex-direction: column;
		gap: 6px;
		cursor: pointer;
		z-index: 999;
	}
}
