.ServicesHome {
	display: flex;
	width: 100vw;
	flex-direction: column;
	align-items: center;
	color: var(--color-text);
}

.ServicesHome .heading {
	font-family: var(--font-family-heading);
	font-size: 450%;
	margin-bottom: 3rem;
}

.ServicesHome .services {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	gap: 2rem 3rem;
}

.ServicesHome .bookButton {
	margin: 3rem 0;
	padding: 1rem 4rem;
	cursor: pointer;
	background-color: var(--color-text);
	font-size: 100%;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 50px;
	transition: 0.5s ease;
}

.ServicesHome .bookButton:hover {
	scale: 1.1;
}

@media screen and (max-width: 900px) {
	.ServicesHome .heading {
		font-size: 250%;
		margin-bottom: 2rem;
	}
}
