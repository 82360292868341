.Feedbacks {
	display: flex;
	width: 100vw;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: var(--color-text);
	background-color: var(--color-bg);
}

.Feedbacks .heading {
	font-family: var(--font-family-heading);
	font-size: 450%;
	width: 50vw;
	text-align: center;
	margin-bottom: 3rem;
}

.Feedbacks .feedbacks-container {
	display: flex;
	flex-wrap: wrap;
	width: 100vw;
	justify-content: space-evenly;
}

@media screen and (max-width: 900px) {
	.Feedbacks .heading {
		font-size: 250%;
		width: 100vw;
		margin-bottom: 2rem;
	}
}
