.AboutUs .content {
	color: var(--color-text);
}

.AboutUs .content .section {
	display: flex;
	gap: 2rem 4rem;
	align-items: center;
	padding-top: 4rem;
}

.AboutUs .content .section:nth-child(even) {
	background-color: #fff;
	flex-direction: row-reverse;
}

.AboutUs .content .section .text {
	width: calc(50vw - 4rem);
}

.AboutUs .content .heading {
	font-family: var(--font-family-heading);
	font-size: 300%;
	margin: 1rem 0;
}

.AboutUs .content .description {
	font-family: var(--font-family-body);
	font-size: 100%;
}

.AboutUs .content .section .image {
	border-radius: 20px;
	flex: auto;
}

.AboutUs .content .section img {
	border-radius: 20px;
	width: 100%;
}

@media screen and (max-width: 900px) {
	.AboutUs .content .section {
		flex-wrap: wrap;
	}

	.AboutUs .content .section .text {
		width: 100vw;
	}
}
