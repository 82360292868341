*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s;
}

a {
	color: unset;
	text-decoration: none;
}

.section__padding {
	padding: 2rem;
}

.section__large_padding {
	padding: 8rem 2rem;
}

.section__xlarge_padding {
	padding: 12rem 2rem 4rem;
}

.fadeIn {
	opacity: 0;
}

@media screen and (max-width: 900px) {
	.section__padding {
		padding: 1rem;
	}

	.section__large_padding {
		padding: 2rem 1rem;
	}

	.section__xlarge_padding {
		padding: 3rem 2rem 2rem;
	}
}
