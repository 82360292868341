.FeedbackCard {
	position: relative;
	max-width: 300px;
	border-radius: 25px;
	background-color: #fff;
	margin: 2rem;
}

.FeedbackCard .image {
	margin: 2rem;
}

.FeedbackCard .image img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-fit: cover;
}

.FeedbackCard .content {
	font-family: var(--font-family-body);
	font-weight: lighter;
	color: var(--color-text);
	margin: 2rem 2rem 8rem;
}

.FeedbackCard .author {
	position: absolute;
	bottom: 2rem;
	font-family: var(--font-family-body);
	color: var(--color-text);
	margin: 0 2rem;
}
