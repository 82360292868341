.Footer {
	width: 100vw;
	color: var(--color-text);
	font-family: var(--font-family-body);
	padding: 4rem 2rem 0;
}

.Footer .details {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem 10rem;
}

.Footer .details .logo {
	display: flex;
	font-size: x-large;
}

.Footer .details .logo img {
	height: 50px;
	width: auto;
	margin-top: -17px;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.Footer .details h4 {
	margin: 0.5rem 0;
}

.Footer .copyright {
	margin: 4rem 0 2rem;
	padding-top: 2rem;
	text-align: center;
	border-top: 1px solid var(--color-text);
}

.Footer .copyright p {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

@media screen and (max-width: 900px) {
	.Footer {
		padding: 2rem 1rem;
	}

	.Footer .copyright {
		margin: 2rem 0 0;
		padding-top: 2rem;
	}
}
