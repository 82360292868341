@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Work+Sans:wght@600&display=swap");

:root {
	--font-family-heading: "Work Sans", sans-serif;
	--font-family-body: "Poppins", sans-serif;

	--color-bg: #acd5de;
	--color-hover: #0f2d52;
	--color-text: #1a4e8d;
	--color-link: #0000ee;
	--color-bg-button: #ffd100;
}
