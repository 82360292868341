.Breadcrumbs {
	font-family: var(--font-family-heading);
	font-size: 150%;
	display: flex;
	justify-content: center;
	margin-top: 2rem;
}

.Breadcrumbs .crumb {
	display: flex;
	gap: 0.5rem;
	margin: 0 0.25rem;
}

.Breadcrumbs .crumb::after {
	content: ">";
}

.Breadcrumbs .crumb:last-child::after {
	content: "";
}

@media screen and (max-width: 900px) {
	.Breadcrumbs {
		font-size: 100%;
		margin-top: 1rem;
	}
}
