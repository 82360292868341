.ContactUs {
	color: var(--color-text);
}

.ContactUs .content {
	width: 70vw;
}

.ContactUs .content .heading {
	font-family: var(--font-family-heading);
	font-size: 300%;
	margin: 1rem 0;
}

.ContactUs .contact-form {
	display: flex;
	flex-wrap: wrap;
	gap: 1rem 2rem;
	margin: 4rem 0;
	font-family: var(--font-family-body);
}

.ContactUs .contact-form .input-details {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	font-size: 100%;
}

.ContactUs .contact-form .break {
	flex-basis: 100%;
	width: 0;
	height: 0;
}

.ContactUs .contact-form input,
.ContactUs .contact-form textarea {
	border: none;
	outline: none;
	margin: 1rem 0;
	background-color: var(--color-bg);
	font-family: var(--font-family-body);
	font-size: 100%;
	border-bottom: 1px solid var(--color-text);
	resize: none;
}

.ContactUs .contact-form input:hover,
.ContactUs .contact-form input:focus,
.ContactUs .contact-form textarea:hover,
.ContactUs .contact-form textarea:focus {
	border-bottom: 2px solid var(--color-hover);
}

.ContactUs .submit-button {
	margin: 3rem 0;
	padding: 0.75rem 3rem;
	cursor: pointer;
	background-color: var(--color-text);
	font-size: 100%;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 50px;
	transition: 0.5s ease;
}

.ContactUs .submit-button:hover {
	background-color: var(--color-hover);
}

.animations {
	position: fixed;
	display: none;
	justify-content: center;
	align-items: center;
	width: 100vw;
	height: 100vh;
	top: 0;
	background-color: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(10px);
	z-index: 99999;
}

.animations.running {
	display: flex;
}

.animations .loading,
.animations .email-sent,
.animations .email-not-sent {
	display: none;
}

.animations .loading.current,
.animations .email-sent.current {
	display: block;
}

.animations .email-not-sent.current {
	width: 100vw;
	height: 100vh;
	background-color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 900px) {
	.ContactUs .content {
		width: 100vw;
	}

	.ContactUs .content .heading {
		font-size: 200%;
	}
}
