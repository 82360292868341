.Header {
	height: 80vh;
}

.Header .container {
	display: flex;
	position: relative;
	height: 95vh;
}

.Header .content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 60vw;
	height: 60vh;
	color: var(--color-text);
}

.Header .content h1 {
	font-family: var(--font-family-heading);
	font-size: 550%;
	line-height: 90%;
	margin: 1rem 0;
}

.Header .content p {
	font-family: var(--font-family-body);
	font-size: 150%;
}

.Header .image {
	position: absolute;
	width: 60vw;
	bottom: 0;
	right: 0;
}

.Header .image img {
	width: 100%;
}

@media screen and (max-width: 900px) {
	.Header {
		height: 70vh;
	}

	.Header .container {
		height: 75vh;
	}

	.Header .content {
		width: 100vw;
	}

	.Header .content h1 {
		font-size: 300%;
	}

	.Header .content p {
		font-size: 120%;
	}

	.Header .image {
		width: 90vw;
		bottom: 0;
		right: 0;
	}
}
