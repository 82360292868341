.ServiceHome {
	/* margin: 0 2rem; */
	text-align: center;
}

.ServiceHome .serviceCard {
	padding: 2rem;
	background-color: #bdebf5;
	border-radius: 35%;
	transition: 0.5s ease;
}

.ServiceHome .serviceCard:hover {
	background-color: var(--color-bg);
}

.ServiceHome .serviceCard img {
	width: 3em;
	height: 3rem;
	cursor: pointer;
	transition: 0.5s ease;
}

.ServiceHome .serviceCard img:hover {
	scale: 1.2;
}

.ServiceHome .serviceName {
	margin: 1rem 0 0 0;
	font-family: var(--font-family-body);
	color: #000;
}
