.ServiceDetailsContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.ServiceDetails {
	background-color: #fff;
	font-family: var(--font-family-body);
	color: var(--color-text);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 70vw;
	margin: 1rem 0;
	padding: 2rem;
}

.ServiceDetails > * {
	margin: 1rem 0;
	text-align: center;
}

.ServiceDetails .service-name {
	position: relative;
}

.ServiceDetails .tooltip {
	position: absolute;
	font-size: medium;
	margin-left: 0.3rem;
	cursor: pointer;
}

.tippy-content {
	font-family: var(--font-family-body);
	font-size: medium;
}

.ServiceDetails .service-details {
	display: flex;
	gap: 1rem 4rem;
}

.ServiceDetails .service-cost-and-duration {
	text-align: center;
}

.ServiceDetails .service-cost-and-duration .cost::before {
	content: "\20B9";
}

.ServiceDetails .bookButton {
	padding: 1rem 4rem;
	cursor: pointer;
	background-color: var(--color-text);
	font-size: 100%;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 50px;
	transition: 0.5s ease;
}

.ServiceDetails .bookButton:hover {
	background-color: var(--color-hover);
}

@media screen and (max-width: 900px) {
	.ServiceDetails {
		flex-direction: column;
		width: calc(100vw - 2rem);
		margin: 0.5rem 0;
	}

	.ServiceDetails .service-details {
		flex-direction: column;
	}

	.ServiceDetails .tooltip-text {
		position: absolute;
		right: 0;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.7);
		font-size: medium;
		font-weight: 100;
		width: max-content;
		max-width: 80vw;
		padding: 1rem;
		border-radius: 10px;
		transform: translate(5%, -100%);
		margin-top: -0.5rem;
	}

	.ServiceDetails .tooltip-text::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 95%;
		margin-left: -12px;
	}
}
